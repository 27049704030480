import { UpdateState, UpdateType } from "./UpdateProvider";

type Action = { type: UpdateType.UpdateAppVersion; appVersion: string | null } | { type: UpdateType.HideReleaseNotes };

export function reducer(state: UpdateState, action: Action): UpdateState {
    switch (action.type) {
        case UpdateType.UpdateAppVersion:
            let newAppVersion = action.appVersion || "";
            const currentAppVersion = (state || []).appVersion || localStorage.getItem("appVersion");

            let hasCurrentVersion = !!currentAppVersion;

            let isNewVersion = newAppVersion !== currentAppVersion;

            if (hasCurrentVersion && !isNewVersion) return state;

            localStorage.setItem("appVersion", newAppVersion);

            const showBanners = hasCurrentVersion && isNewVersion;

            if (showBanners) localStorage.setItem("isUpdateSuccessful", "true");

            return {
                ...state,
                appVersion: newAppVersion,
                isUpdateAvailable: showBanners,
                isUpdateSuccessful: showBanners,
            };

        case UpdateType.HideReleaseNotes:
            localStorage.setItem("isUpdateSuccessful", "false");
            return {
                ...state,
                isUpdateSuccessful: false,
            };
        default:
            throw new Error("Invalid action");
    }
}
