import { Messages } from ".";

const messages: Messages = {
    appName: "",
    "Queue.category.Waiting": "",
    "Queue.category.Waiting.description": "",
    "Queue.category.ReturnRequested": "",
    "Queue.category.ReturnRequested.description": "",
    "Queue.category.HelpNext": "",
    "Queue.category.HelpNext.description": "",
    "Button.remove": "",
    "Button.mark.as.done": "",
    "Button.request.return": "",
    "Button.request.return.again": "",
    "Button.more.actions": "",
    "Button.request.next.return": "",
    "Timestamp.since": "",
    "Timestamp.at": "",
    DatetimeFormat: "",
    Joined: "",
    "Scheduled.for": "",
    Arrived: "",
    Requested: "",
    Returned: "",
    "Customer.call": "",
    "Customer.cancel": "",
    "Button.select": "Select",
    "Customer.info.title": "",
    "Locations.page": "",
    "Input.search": "",
    "Drawer.title": "",
    "Drawer.change.location": "",
    "Drawer.log.out": "",
    "Landing.page": "",
    "Landing.page.button": "",
    "Error.permission.header": "",
    "Error.permission.subTitle": "",
    "Error.no.locations.subTitle": "",
    "Error.page.not.working.header": "",
    "Error.page.not.working.subTitle": "",
    "Add.customer.modal.add.customer": "",
    "Add.customer.modal.how.to.add.customer": "",
    "Add.customer.modal.reason.for.visit": "",
    "Add.customer.modal.how.to.add.customer.text": "",
    "Add.customer.modal.how.to.add.customer.schedule": "",
    "Add.customer.modal.how.to.add.customer.text.small.text": "",
    "Add.customer.modal.how.to.add.customer.schedule.small.text": "",
    "Add.customer.modal.phone": "",
    "Add.customer.modal.schedule.return.time": "",
    "Add.customer.modal.phone.number.must.be.length": "",
    "Add.customer.modal.phone.number.not.valid": "",
    "Add.customer.modal.name": "",
    "Add.customer.required.field": "",
    "Add.customer.modal.date.future": "",
    "History.card.title": "",
    "History.card.subTitle": "",
    "App.menu.active.queue.title": "",
    "App.menu.history.title": "",
    "App.menu.report.title": "",
    "Create.report.modal.select.location": "",
    "Create.report.modal.by.location": "",
    "Create.report.modal.entire.company": "",
    "Create.report.modal.locations.selected": "",
    "Request.report.date.range": "",
    "Request.report.card.subtitle": "",
    "Request.report.card.title": "",
    "Request.report.card.location.label": "",
    "Request.report.card.input.placeholder": "",
    "Button.text.export": "",
    "Request.report.alert.title": "",
    "Request.report.alert.description": "",
    "Request.report.start.date": "",
    "Request.report.end.date": "",
    "Request.report.default.name": "",
    "Button.show.more": "",
    "Button.show.less": "",
    "Error.no.network.title": "",
    "Error.timeout.subTitle": "",
    "Error.no.network.subTitle": "",
    "Error.queue.problem.title": "",
    "Error.queue.problem.subTitle": "",
};

export default messages;
