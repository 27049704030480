import React, { useState } from "react";
import { Card, Skeleton, Typography, Row, Col, Button, Space, Empty } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { Customer, QueueState, CustomerByState } from "models";
import { Messages, verticalGutter, wholeGutter, queueMaxCardsShown } from "shared";
import { CustomerActions } from "./CustomerActions";
import { CustomerInfo } from "./CustomerInfo";
import { RequestFirstCustomerButton } from "./RequestFirstCustomerButton";

const { Paragraph } = Typography;

interface QueueCardProps {
    queueState: QueueState;
    customersByState: CustomerByState;
    isCollapsible?: boolean;
    loading: boolean;
    reloadQueue: () => void;
    disabled: boolean;
}

export const QueueCard: React.FC<QueueCardProps> = ({
    queueState,
    customersByState,
    loading,
    isCollapsible = false,
    disabled,
    reloadQueue,
}: QueueCardProps) => {
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);
    const customers: Customer[] = customersByState ? customersByState[queueState] : [];
    // only show first 3 customers if it is collapsed
    const customersToShow = isCollapsed ? customers.slice(0, queueMaxCardsShown) : customers;
    const cardHeader: keyof Messages = `Queue.category.${queueState}` as keyof Messages;
    const cardDescription: keyof Messages = `Queue.category.${queueState}.description` as keyof Messages;
    const showMoreButtonText = useFormatMessage("Button.show.more", { number: customers.length - queueMaxCardsShown });
    const showLessButtonText = useFormatMessage("Button.show.less");

    const toggleCollapse = () => {
        setIsCollapsed((isCollapsed) => !isCollapsed);
    };

    return (
        <Card title={useFormatMessage(cardHeader)} className="queue-card">
            <Paragraph className="card-paragraph">{useFormatMessage(cardDescription)}</Paragraph>
            <Skeleton loading={loading} title={false} paragraph={{ rows: 3 }}>
                {!customers || customers.length === 0 ? (
                    <Empty>
                        {queueState === "ReturnRequested" && customersByState["Waiting"].length > 0 && (
                            <RequestFirstCustomerButton customer={customersByState["Waiting"]?.[0]} />
                        )}
                    </Empty>
                ) : (
                    <Space direction="vertical">
                        <Row gutter={[0, verticalGutter]}>
                            <Col span={wholeGutter}>
                                <Space direction="vertical">
                                    {customersToShow.map((customer: Customer) => (
                                        <Card className="customer-info-card" key={customer.id}>
                                            <CustomerInfo
                                                name={customer.name}
                                                phoneNumber={customer.phoneNumber}
                                                reasonCode={customer.reasonCode}
                                                date={
                                                    customer.scheduledDateTime ||
                                                    customer.waitingSinceDateTime ||
                                                    customer.returnRequestedDateTime ||
                                                    customer.queuedDate
                                                }
                                                arrivedDate={
                                                    (customer.entryPoint === "Appointment" && customer.queuedDate) ||
                                                    undefined
                                                }
                                                queueState={customer.queueState}
                                                preferredLanguage={customer.preferredLanguage}
                                            />
                                            <CustomerActions
                                                customer={customer}
                                                queueState={queueState}
                                                reloadQueue={reloadQueue}
                                                disabled={disabled}
                                            />
                                        </Card>
                                    ))}
                                </Space>
                            </Col>
                        </Row>
                        {customers.length > queueMaxCardsShown && isCollapsible && (
                            <Row>
                                <Col span={wholeGutter} className="text-center">
                                    <Button type="link" onClick={toggleCollapse}>
                                        {isCollapsed ? showMoreButtonText : showLessButtonText}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Space>
                )}
            </Skeleton>
        </Card>
    );
};
