import { useReducer, useContext, useEffect, useCallback } from "react";
import moment from "moment";
import { getCustomerEndpoint, authenticatedFetch, dateFormat } from "shared";
import { Customer } from "models";
import { authStore, appStore } from "providers";

type Action =
    | { type: "reset" }
    | { type: "success"; customers: Array<Customer> }
    | { type: "loading" }
    | { type: "error"; error: Response; failedInitially?: boolean };

interface State {
    status: "empty" | "error" | "ready" | "load";
    error: Response | null;
    counter: number;
    failedInitially: boolean;
    customers: Array<Customer>;
}
const initialState: State = { status: "empty", error: null, counter: 0, failedInitially: false, customers: [] };
let INTERVAL_ID: number;

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "reset":
            return {
                failedInitially: false,
                error: null,
                counter: 0,
                customers: [],
                status: "empty",
            };
        case "success":
            return {
                failedInitially: false,
                status: "ready",
                error: null,
                customers: action.customers,
                counter: 0,
            };
        case "loading":
            return {
                ...state,
                status: "load",
            };
        case "error":
            return {
                ...state,
                status: "error",
                error: action.error,
                failedInitially: action.failedInitially || state.failedInitially,
                counter: (state.counter || 0) + 1,
            };
        default:
            throw new Error("Invalid action");
    }
}

export function useCustomerQueue(): [State, () => Promise<void>] {
    const [state, dispatch] = useReducer(reducer, initialState);
    const authContext = useContext(authStore);
    const appContext = useContext(appStore);
    const { user } = authContext.state;
    const { locationId } = appContext.state;

    const fetchQueue = useCallback(async () => {
        const entityId = user.ParentEntityId;
        if (entityId && locationId) {
            const date = moment().format(dateFormat);
            const url = getCustomerEndpoint(entityId, locationId, user.Id, date);
            const res = await authenticatedFetch(url);
            if (!res.ok && res.status >= 300) throw res;
            const results = await res.json();
            return results.data;
        }
    }, [locationId, user.Id, user.ParentEntityId]);

    const getCustomerQueue = useCallback(async () => {
        try {
            const customers = await fetchQueue();
            dispatch({ type: "success", customers });
        } catch (error) {
            dispatch({ type: "error", error });
        }
    }, [fetchQueue]);

    useEffect(() => {
        // only show loading on first page load
        dispatch({ type: "loading" });
        const getQueue = async () => {
            try {
                const customers = await fetchQueue();
                dispatch({ type: "success", customers });
            } catch (error) {
                dispatch({ type: "error", error, failedInitially: true });
            }
        };
        getQueue();
    }, [fetchQueue]);

    useEffect(() => {
        const pollCustomerQueue = () => {
            if (INTERVAL_ID) {
                window.clearInterval(INTERVAL_ID); // if there is already polling happening we stop all subsequent requests
            }
            const id = window.setInterval(getCustomerQueue, 10000); //every 10 seconds
            INTERVAL_ID = id;
        };
        pollCustomerQueue();
        return () => {
            return window.clearInterval(INTERVAL_ID);
        };
    }, [getCustomerQueue]);

    return [state, getCustomerQueue];
}
