import React, { useContext } from "react";
import { Skeleton, Row, Col, Typography } from "@iqmetrix/antd";
import { halfGutter } from "shared";
import { locationStore } from "providers";
import { useFormatPhoneNumber } from "hooks";

const { Title, Paragraph } = Typography;

export const LocationPane: React.FC = (props) => {
    const {
        state: { config, isLoading },
    } = useContext(locationStore);

    const formattedPhoneNumber = useFormatPhoneNumber(config ? config.configuration.PhoneNumber : "");

    return (
        <Row className="content-container">
            <Col span={halfGutter}>
                <Skeleton paragraph={{ rows: 1 }} loading={isLoading}>
                    <Title level={4}> {config?.displayName} </Title>
                    <Paragraph> {formattedPhoneNumber} </Paragraph>
                </Skeleton>
            </Col>
            {props.children && (
                <Col className="text-right" span={halfGutter}>
                    {props.children}
                </Col>
            )}
        </Row>
    );
};
