import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { authStore } from "providers";

interface PrivateRouteProps extends RouteProps {
    component: React.FC<any>
}

export const PrivateRoute = ({ component: Component }: PrivateRouteProps) => {
    const {
        state: { isAuthenticated, isAuthLoading },
    } = useContext(authStore);

    return (
        <Route
            render={(routeProps: RouteProps) =>
                !isAuthenticated && !isAuthLoading ? (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: routeProps.location },
                        }}
                    />
                ) : (
                    <Component {...routeProps}></Component>
                )
            }
        />
    );
};
