import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga4";
import { Row, Col, Grid, Layout, Space, Button, Result, Alert } from "@iqmetrix/antd";
import { Customer, QueueState, CustomerByState } from "models";
import {
    responsiveColumnsHorizontal,
    queueStates,
    horizontalGutter,
    verticalGutter,
    wholeGutter,
    maxRepeatedError,
} from "shared";
import { LocationPane, AppMenu } from "components";
import { appStore, authStore } from "providers";
import { QueueCard } from "./components/QueueCard";
import { AddCustomerModal } from "./components/AddCustomerModal";
import { useResult, useFormatMessage } from "hooks";
import { useCustomerQueue } from "./hooks/use-customer-queue";

const { useBreakpoint } = Grid;

const appQueueStates: QueueState[] = queueStates;

const groupCustomersByState = (customers: Array<Customer>) => {
    return customers.reduce(
        (grouped: CustomerByState, customer: Customer) => {
            switch (customer.queueState) {
                case "InLine":
                    grouped["Waiting"].push(customer);
                    break;
                case "ReturnRequested":
                    grouped["ReturnRequested"].push(customer);
                    break;
                case "Scheduled":
                case "HelpNext":
                case "Waiting":
                    grouped["HelpNext"].push(customer);
                    break;
            }
            return grouped;
        },
        {
            Waiting: [] as Customer[],
            ReturnRequested: [] as Customer[],
            HelpNext: [] as Customer[],
        } as CustomerByState
    );
};

const initialState = {
    Waiting: [] as Customer[],
    ReturnRequested: [] as Customer[],
    HelpNext: [] as Customer[],
} as CustomerByState;

export const Queue: React.FC = () => {
    const authContext = useContext(authStore);
    const appContext = useContext(appStore);
    const [customersByState, setCustomersByState] = useState(initialState);
    const [showModal, setShowModal] = useState(false);
    // For perimssion, network and no assigned locations errors
    const { showResult, resultContent } = useResult();
    const [queueState, getCustomerQueue] = useCustomerQueue();
    const addCustomerText = useFormatMessage("Add.customer.modal.add.customer");
    const alertTitle = useFormatMessage("Error.queue.problem.title");
    const alertDescription = useFormatMessage("Error.queue.problem.subTitle");
    const screens = useBreakpoint();
    const { user } = authContext.state;
    const { locationId } = appContext.state;
    const showDesktopView = screens.lg;
    const isLoading = queueState.status === "load";
    let alertContent = null;

    useEffect(() => {
        if (queueState.status === "ready") {
            const customersInQueue = queueState.customers ?? [];
            const grouping = groupCustomersByState(customersInQueue);
            // Sort scheduled customers by scheduledDateTime
            if (grouping["Scheduled"]) {
                grouping["Scheduled"].sort((customerA: Customer, customerB: Customer) => {
                    if (customerA.scheduledDateTime && customerB.scheduledDateTime) {
                        return Date.parse(customerA.scheduledDateTime) - Date.parse(customerB.scheduledDateTime);
                    } else {
                        return Date.parse(customerA.queuedDate) - Date.parse(customerB.queuedDate);
                    }
                });
            }
            setCustomersByState(grouping);
        }
    }, [queueState]);

    const toggleAddCustomerModal = (showModal: boolean, action?: string) => {
        setShowModal(showModal);
        if (action) {
            ReactGA.event({
                category: `LocationID ${locationId} `,
                action,
                label: `CustomerID ${user.Id}`,
            });
        }
    };

    // For permission, network and no assigned locations errors show result page
    if (showResult && resultContent) return resultContent;

    // For fetching the queue related errors, show reult page on initial fail,
    // show alert when it fails after it has previously been fetched
    if (queueState.error) {
        if (queueState.failedInitially || queueState.counter > maxRepeatedError) {
            return <Result icon={null} response={{ status: queueState.error.status }} />;
        } else {
            alertContent = (
                <Alert
                    type="warning"
                    description={alertDescription}
                    message={alertTitle}
                    className={showDesktopView ? "desktop-margin" : "mobile-margin"}
                />
            );
        }
    }

    const disableButtons = queueState.status === "error";

    return (
        <Space direction="vertical" size="middle">
            <AppMenu></AppMenu>
            {alertContent}
            <LocationPane>
                <Button
                    type="default"
                    id="add-customer-button"
                    disabled={disableButtons}
                    onClick={() => toggleAddCustomerModal(true, "Open modal")}
                >
                    {addCustomerText}
                </Button>
            </LocationPane>
            <AddCustomerModal visible={showModal} toggleModal={toggleAddCustomerModal} reloadQueue={getCustomerQueue} />
            <Layout className="content-container">
                {showDesktopView ? (
                    <Row justify="center" gutter={[horizontalGutter, verticalGutter]}>
                        {appQueueStates.map((queueState: QueueState) => (
                            <Col key={queueState} {...responsiveColumnsHorizontal}>
                                <QueueCard
                                    queueState={queueState}
                                    customersByState={customersByState}
                                    loading={isLoading}
                                    reloadQueue={getCustomerQueue}
                                    disabled={disableButtons}
                                />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    appQueueStates.map((queueState: QueueState) => (
                        <Row justify="center" key={queueState} gutter={[horizontalGutter, verticalGutter]}>
                            <Col span={wholeGutter}>
                                <QueueCard
                                    queueState={queueState}
                                    customersByState={customersByState}
                                    isCollapsible={true}
                                    loading={isLoading}
                                    reloadQueue={getCustomerQueue}
                                    disabled={disableButtons}
                                />
                            </Col>
                        </Row>
                    ))
                )}
            </Layout>
        </Space>
    );
};
