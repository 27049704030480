const messages = {
    appName: "Queue Management",
    "Queue.category.Waiting": "Waiting",
    "Queue.category.Waiting.description": "Customers joined and waiting for an available sales representative.",
    "Queue.category.ReturnRequested": "Return requested",
    "Queue.category.ReturnRequested.description": "Customers asked to return to the store to be helped next.",
    "Queue.category.HelpNext": "Help next",
    "Queue.category.HelpNext.description": "Customers ready to be helped or with a scheduled return time.",
    "Button.remove": "Remove",
    "Button.mark.as.done": "Mark as done",
    "Button.request.return": "Request return",
    "Button.request.return.again": "Request return again",
    "Button.request.next.return": "Request the next customer return to store",
    "Button.more.actions": "More actions",
    "Timestamp.since": "since {queuedDate}",
    "Timestamp.at": "at {queuedDate}",
    DatetimeFormat: "MMM D [at] h:mma",
    Joined: "Joined {dateString}",
    "Scheduled.for": "Scheduled for {queuedDate}",
    Arrived: "Arrived at {arrivedDate}",
    Requested: "Requested {queuedDate}",
    Returned: "Returned {dateString}",
    "Customer.call": "Call",
    "Customer.cancel": "Cancel",
    "Button.select": "Select",
    "Customer.info.title": "Customer information",
    "Locations.page": "Please select your location",
    "Input.search": "Search locations",
    "Drawer.title": "Account",
    "Drawer.change.location": "Change location",
    "Drawer.log.out": "Sign out",
    "Landing.page": "Welcome to your Queue!",
    "Landing.page.button": "LOGIN",
    "Error.permission.header": "You do not have permission to view this page.",
    "Error.permission.subTitle": "Please contact your systems administrator to request permission.",
    "Error.no.locations.subTitle": "Please contact your systems administrator to assign you a location.",
    "Error.page.not.working.header": "This page is not working.",
    "Error.page.not.working.subTitle": "The browser sent a request that the server does not understand.",
    "Add.customer.modal.add.customer": "Add customer",
    "Add.customer.modal.reason.for.visit": "Reason for visit",
    "Add.customer.modal.how.to.add.customer": "How would you like to add the customer?",
    "Add.customer.modal.how.to.add.customer.text": "Text message",
    "Add.customer.modal.how.to.add.customer.schedule": "Schedule",
    "Add.customer.modal.how.to.add.customer.text.small.text":
        "The customer will interact with the queue using their device to send/receive messages via text.",
    "Add.customer.modal.how.to.add.customer.schedule.small.text":
        "Please suggest an approximate time for the customer to return. The customer is unable to interact with the queue using their device.",
    "Add.customer.modal.phone": "Phone",
    "Add.customer.modal.schedule.return.time": "Suggested return time",
    "Add.customer.modal.phone.number.must.be.length": "Phone number requires 10 digits",
    "Add.customer.modal.phone.number.not.valid": "Phone number is not valid",
    "Add.customer.modal.name": "Name",
    "Add.customer.required.field": "Required",
    "Add.customer.modal.date.future": "Please select a time that is later today",
    "History.card.title": "Today’s history",
    "History.card.subTitle": "Customers that have interacted with the queue today.",
    "App.menu.active.queue.title": "Active queue",
    "App.menu.history.title": "Today's history",
    "App.menu.report.title": "Report",
    "Create.report.modal.select.location": "Select locations",
    "Create.report.modal.by.location": "By location",
    "Create.report.modal.entire.company": "Entire company",
    "Create.report.modal.locations.selected": "{numberOf, plural, =1 {# location} other {# locations}}",
    "Request.report.date.range": "Date range",
    "Request.report.card.subtitle": "Customers that have interacted with the queue.",
    "Request.report.card.title": "Queue history",
    "Request.report.card.location.label": "Location(s)",
    "Request.report.card.input.placeholder": "Select a location",
    "Button.text.export": "Export",
    "Request.report.alert.title": "File exported successfully.",
    "Request.report.alert.description":
        "Please check your system’s “Downloads” folder if you cannot see the downloaded file within your browser.",
    "Request.report.start.date": "Start date",
    "Request.report.end.date": "End date",
    "Request.report.default.name": "QueueReport",
    "Button.show.more": "show more ({number})",
    "Button.show.less": "show less",
    "Error.no.network.title": "The application has encountered a timeout.",
    "Error.no.network.subTitle": "Attempting to reconnect... If the problem persists, report this error to",
    "Error.timeout.subTitle": "Please try again. If the problem persists, report this error to",
    "Error.queue.problem.title": "There was a problem updating the queue.",
    "Error.queue.problem.subTitle":
        "You will be unable to complete any actions until the system reconnects successfully. Please wait or refresh the page to try again.",
};

export default messages;
