import React, { useContext } from "react";
import { Form, Radio, Spin } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { ReasonCode } from "models";
import { locationStore } from "providers";

export const ReasonForVisit: React.FC = () => {
    const {
        state: { reasonCodes, isLoading },
    } = useContext(locationStore);
    const requiredText: string = useFormatMessage("Add.customer.required.field");
    const reasonForVisitLabel: string = useFormatMessage("Add.customer.modal.reason.for.visit");

    return (
        <Form.Item label={reasonForVisitLabel} name="reasonCode" rules={[{ required: true, message: requiredText }]}>
            <Spin spinning={isLoading}>
                <Radio.Group>
                    {reasonCodes?.map((code: ReasonCode) => (
                        <Radio key={code.id} value={code.id}>
                            {code.displayName}
                        </Radio>
                    ))}
                </Radio.Group>
            </Spin>
        </Form.Item>
    );
};
