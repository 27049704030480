import * as React from "react";

import { Result } from "@iqmetrix/antd";
import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

const Fallback: FallbackRender = ({ error, componentStack, resetError, eventId }) => {
  resetError();
  return <Result icon={null} response={error.message} />;
};

export const ErrorBoundary = ({ children }: React.PropsWithChildren<{}>) => (
    <Sentry.ErrorBoundary fallback={Fallback}>
        {children}
    </Sentry.ErrorBoundary>
);
