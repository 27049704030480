import React, { useContext, useEffect } from "react";
import { withGARouter } from "shared/analytics"
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Layout, Spin, Row } from "@iqmetrix/antd";
import { Queue, Landing, Locations, History, Report } from "pages";
import { AppHeader, PrivateRoute } from "components";
import { appStore, authStore, LocationProvider, updateStore } from "providers";
import TagManager from "react-gtm-module";

TagManager.initialize({
    gtmId: "GTM-5R7XPHB",
    auth: "A0OyBsFoWOa_uHsQWMAvXQ",
});

const { Content } = Layout;

export const App: React.FC = () => {
    const {
        state: { user, isAuthenticated, isAuthLoading },
    } = useContext(authStore);
    const {
        state: { isLoadingApp },
    } = useContext(appStore);

    const { updateAppVersion } = useContext(updateStore);

    useEffect(() => {
        setInterval(updateAppVersion, 10 * 60 * 1000);
    }, [updateAppVersion]);

    // Add your app's routes below
    // Routing Docs: https://reacttraining.com/react-router/web/guides/quick-start
    // Routing Standards: https://design-system.iqmetrix.net/docs/web-dev-docs/guides/routing-standards

    if (process.env.NODE_ENV !== "development" && isAuthenticated && user) {
        ReactGA.set({ userId: user.Id });
    }

    return (
        <LocationProvider>
            <Layout>
                <AppHeader></AppHeader>
                {isLoadingApp || isAuthLoading ? (
                    <Row justify="space-around" align="middle" className="center-page-content">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <Content>
                        <Switch>
                            <PrivateRoute
                                exact
                                path="/locations/:id/queue/history"
                                component={withGARouter(History)}
                            ></PrivateRoute>
                            <PrivateRoute exact path="/locations/:id/queue/report" component={withGARouter(Report)} />
                            <PrivateRoute exact path="/locations/:id/queue" component={withGARouter(Queue)} />
                            <PrivateRoute exact path="/locations" component={withGARouter(Locations)} />
                            <PrivateRoute exact path="/" component={withGARouter(Queue)} />
                            <Route exact path="/login" component={withGARouter(Landing)} />
                        </Switch>
                    </Content>
                )}
            </Layout>
        </LocationProvider>
    );
};
