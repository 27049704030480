import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import { Modal, Button, Typography } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { useFormatPhoneNumber } from "hooks";
import { authStore, appStore } from "providers";
import { QueueState } from "models";

const { Paragraph, Text } = Typography;

interface CustomerInfoModal {
    phoneNumber: string;
    name: string;
    queueState: QueueState;
}

export const CustomerInfoModal: React.FC<CustomerInfoModal> = (props: CustomerInfoModal) => {
    const [showModal, setShowModal] = useState(false);

    const callString = useFormatMessage("Customer.call");
    const cancelString = useFormatMessage("Customer.cancel");
    const customerInfoTitle = useFormatMessage("Customer.info.title");
    const { name, phoneNumber, queueState } = props;
    const {
        state: { locationId },
    } = useContext(appStore);
    const {
        state: { user },
    } = useContext(authStore);
    const formattedPhoneNumber: string = useFormatPhoneNumber(phoneNumber);

    const changeModalState = (showModal: boolean) => {
        setShowModal(showModal);
        if (showModal) {
            trackEvent("Open customer modal");
        }
    };

    const trackEvent = (action: string) => {
        ReactGA.event({
            category: `LocationID ${locationId} `,
            action: action,
            label: `CustomerID ${user.Id}`,
        });
    };

    return (
        <>
            {queueState === "OptedOut" || phoneNumber === undefined ? (
                <Text> {name} </Text>
            ) : (
                <Button type="link" size="small" className="no-padding" onClick={() => changeModalState(true)}>
                    {name}
                </Button>
            )}
            <Modal
                title={customerInfoTitle}
                closable={false}
                visible={showModal}
                footer={[
                    <Button key="close" onClick={() => changeModalState(false)}>
                        {cancelString}
                    </Button>,
                    <Button
                        key="call"
                        type="primary"
                        href={`tel:${phoneNumber}`}
                        onClick={() => trackEvent("Call Customer")}
                    >
                        {callString}
                    </Button>,
                ]}
            >
                <Paragraph> {name} </Paragraph>
                <Paragraph> {formattedPhoneNumber} </Paragraph>
            </Modal>
        </>
    );
};
