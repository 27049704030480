import React, { useContext } from "react";
import { Layout, Affix, Button, Typography, Menu, Dropdown, CaretDownFilled } from "@iqmetrix/antd";
import { SignIn, SignInProps } from "@iqmetrix/antd/dist/components/signin";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useFormatMessage } from "hooks";
import { authStore, appStore, UpdateProvider } from "providers";
import { UpdateAlert } from "components";

const { Header } = Layout;
const { Title } = Typography;

export const AppHeader: React.FC = () => {
    const {
        logout,
        state: { isAuthenticated, user },
    } = useContext(authStore);
    const {
        state: { locationId, availableLocations },
    } = useContext(appStore);

    const history = useHistory();
    const { pathname } = useLocation();
    const isOnQueuePage = pathname.includes("/queue");

    const changeLocationText = useFormatMessage("Drawer.change.location");
    const logOut = useFormatMessage("Drawer.log.out");
    const appName = useFormatMessage("appName");

    const DropdownMenu = () => {
        return (
            <Dropdown key="more" overlay={menu} trigger={["click"]}>
                <Button type="text" size="small" className="user-dropdown">
                    {user.FirstName} {user.LastName} <CaretDownFilled />
                </Button>
            </Dropdown>
        );
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Link
                    to="/"
                    onClick={() => {
                        logout();
                    }}
                >
                    {logOut}
                </Link>
            </Menu.Item>
        </Menu>
    );

    const redirectToQueue = (event: any) => {
        if (isAuthenticated && !!locationId) history.push(`/locations/${locationId}/queue`);
    };

    const signInProps: SignInProps = {
        visible: true,
        items: [
            {
                text: changeLocationText,
                link: "#/locations",
                hidden: !isAuthenticated || availableLocations.length <= 1 || !isOnQueuePage,
            },
            {
                text: logOut,
                onclick: () => logout(),
                hidden: !isAuthenticated,
                className: "signout-link",
            },
        ],
    };

    return (
        <UpdateProvider>
            <Affix>
                <Header className="site-page-header">
                    <Title level={3} className="app-header">
                        <span onClick={redirectToQueue} className="clickable">
                            {appName}
                        </span>
                    </Title>
                    {isAuthenticated && (
                        <div>
                            <div className="right-menu-item">
                                {availableLocations.length > 1 && isOnQueuePage && (
                                    <Link key="location-link" to="/locations">
                                        {changeLocationText}
                                    </Link>
                                )}
                                <DropdownMenu key="app-user" />
                            </div>
                            <div className="mobile-right-menu-item">
                                <SignIn visible={signInProps.visible} items={signInProps.items} />
                            </div>
                        </div>
                    )}
                </Header>
                <UpdateAlert />
            </Affix>
        </UpdateProvider>
    );
};
