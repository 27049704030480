// *******************************************************************
// Update provider provides the application with every functionality
// related to the retrieving and displaying update notifications;
// *******************************************************************
import React, { useReducer, createContext, ReactChild, useEffect } from "react";
import { getAppVersionEndpoint } from "shared";
import { useHistory } from "react-router-dom";
import { reducer } from "./reducer";

export type UpdateState = {
    appVersion: string | null;
    isUpdateAvailable: boolean | false;
    isUpdateSuccessful: boolean | false;
};

export enum UpdateType {
    UpdateAppVersion = "update app version",
    HideReleaseNotes = "hide release notes",
}

type InitialStateType = {
    state: UpdateState;
    updateAppVersion: () => void;
    hideReleaseNotes: () => void;
};

const initialState: UpdateState = {
    appVersion: localStorage.getItem("appVersion") || null,
    isUpdateAvailable: false,
    isUpdateSuccessful: localStorage.getItem("isUpdateSuccessful") === "true",
};

const updateStore = createContext<InitialStateType>({
    state: initialState,
    updateAppVersion: function () {
        // implemented inside the provider
    },
    hideReleaseNotes: function () {
        // implemented inside the provider
    },
});

const { Provider } = updateStore;

const UpdateProvider = ({ children }: { children: ReactChild }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const routerHistory = useHistory();

    const updateAppVersion = async () => {
        try {
            const response = await fetch(getAppVersionEndpoint());
            if (!response.ok) throw response;
            const { ApplicationVersion: appVersion } = await response.json();
            if (state.appVersion !== appVersion) {
                dispatch({ type: UpdateType.UpdateAppVersion, appVersion });
            }
        } catch (error) {
            throw error;
        }
    };

    const hideReleaseNotes = () => {
        dispatch({ type: UpdateType.HideReleaseNotes });
    };

    useEffect(() => {
        routerHistory.listen((location, action) => {
            updateAppVersion();
        });
    });

    return (
        <Provider
            value={{
                state,
                updateAppVersion,
                hideReleaseNotes,
            }}
        >
            {children}
        </Provider>
    );
};

export { updateStore, UpdateProvider };
