import { QueueState } from "models";

export const horizontalGutter = 8;
export const verticalGutter = 8;
export const halfGutter = 12;
export const wholeGutter = 24;

export const responsiveColumnsVertical = { sm: 24, md: 12, lg: 10, xl: 8 };
export const responsiveColumnsHorizontal = { sm: 8, md: 8, lg: 8, xl: 8 };

export const queueStates: QueueState[] = ["HelpNext", "ReturnRequested", "Waiting"];

export const queueManagementPermission = "ManageCheckInQueue";

export const phoneMaxNumber = 10;

export const minuteStep = 15;

export const timeFormat = "h:mma";

export const dateFormat = "MM/DD/YYYY";

export const queueMaxCardsShown = 3;
export const maxRepeatedError = 3;
