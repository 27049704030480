import React, { useEffect } from "react";
import { Button, Row, Col, Dropdown, DownOutlined, Menu } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { horizontalGutter, verticalGutter } from "shared";
import { Customer, QueueState } from "models";
import { useCustomerAction } from "../hooks/use-customer-action";

interface ActionMenuProps {
    text: string;
    danger?: boolean;
    action: () => void;
}

const getMoreActions = (actions: ActionMenuProps[], buttonText: string, disabled: boolean) => {
    const menu = (
        <Menu>
            {actions.map((action) => {
                return (
                    <Menu.Item onClick={action.action} danger={action.danger} key={action.text}>
                        {action.text}
                    </Menu.Item>
                );
            })}
        </Menu>
    );
    return (
        <Dropdown overlay={menu} trigger={["click"]} disabled={disabled}>
            <Button>
                {buttonText} <DownOutlined />
            </Button>
        </Dropdown>
    );
};

interface CustomerActionsProps {
    customer: Customer;
    queueState: QueueState;
    reloadQueue: () => void;
    disabled: boolean;
}

export const CustomerActions: React.FC<CustomerActionsProps> = ({ customer, queueState, reloadQueue, disabled }) => {
    const { state: actionState, remove, requestReturn, requestApproach, markDone } = useCustomerAction(customer);
    const activeAction = actionState.status === "loading" ? actionState.action : null;
    const requestReturnAgainText = useFormatMessage("Button.request.return.again");
    const requestReturnText = useFormatMessage("Button.request.return");
    const removeText = useFormatMessage("Button.remove");
    const markDoneText = useFormatMessage("Button.mark.as.done");
    const getMoreActionsText = useFormatMessage("Button.more.actions");

    let primaryButton: JSX.Element | null = null;
    let secondaryButton: JSX.Element | null = null;

    useEffect(() => {
        if (actionState.status === "ready") {
            reloadQueue();
        }
    }, [actionState, reloadQueue]);

    switch (queueState) {
        case "HelpNext":
            primaryButton = (
                <Button
                    type="primary"
                    loading={activeAction === "MarkDone"}
                    disabled={!!activeAction || disabled}
                    onClick={markDone}
                >
                    {markDoneText}
                </Button>
            );
            if (customer.queueState === "Scheduled") {
                secondaryButton = (
                    <Button
                        className="remove-button"
                        type="link"
                        danger
                        loading={activeAction === "Remove"}
                        disabled={!!activeAction || disabled}
                        onClick={remove}
                    >
                        {removeText}
                    </Button>
                );
            } else {
                secondaryButton = getMoreActions(
                    [
                        { text: requestReturnAgainText, action: requestApproach },
                        { text: removeText, action: remove, danger: true },
                    ],
                    getMoreActionsText,
                    disabled
                );
            }
            break;
        case "ReturnRequested":
            primaryButton = (
                <Button
                    type="primary"
                    loading={activeAction === "MarkDone"}
                    disabled={!!activeAction || disabled}
                    onClick={markDone}
                >
                    {markDoneText}
                </Button>
            );
            secondaryButton = (
                <Button
                    className="remove-button"
                    type="link"
                    danger
                    loading={activeAction === "Remove"}
                    disabled={!!activeAction || disabled}
                    onClick={remove}
                >
                    {removeText}
                </Button>
            );
            break;
        case "Waiting":
            primaryButton = (
                <Button
                    type="primary"
                    loading={activeAction === "RequestReturn"}
                    disabled={!!activeAction || disabled}
                    onClick={requestReturn}
                >
                    {requestReturnText}
                </Button>
            );
            secondaryButton = getMoreActions(
                [
                    { text: markDoneText, action: markDone },
                    { text: removeText, action: remove, danger: true },
                ],
                getMoreActionsText,
                disabled
            );
            break;
        default:
            break;
    }

    return (
        <Row gutter={[horizontalGutter, verticalGutter]} align="middle">
            {secondaryButton && (
                <Col flex={1} className="text-right">
                    {secondaryButton}
                </Col>
            )}
            {primaryButton && <Col>{primaryButton}</Col>}
        </Row>
    );
};
