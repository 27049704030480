import React from "react";
import moment from "moment";
import { Form, TimePicker } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { minuteStep, timeFormat } from "shared";

export const ScheduleReturnTime: React.FC = () => {
    const requiredText: string = useFormatMessage("Add.customer.required.field");
    const dateFuture: string = useFormatMessage("Add.customer.modal.date.future");
    const scheduleReturnTimeLabel: string = useFormatMessage("Add.customer.modal.schedule.return.time");

    return (
        <Form.Item
            label={scheduleReturnTimeLabel}
            name="scheduledDateTime"
            rules={[
                {
                    required: true,
                    message: requiredText,
                },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        const now = moment();
                        if (value) {
                            if (value.isAfter(now)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(dateFuture);
                        } else {
                            return Promise.reject();
                        }
                    },
                }),
            ]}
        >
            <TimePicker use12Hours format={timeFormat} minuteStep={minuteStep} showNow={false} />
        </Form.Item>
    );
};
