// *******************************************************************
// App provider provides the application with every functionality
// related to the application as a whole; get all locations, select
// and clear the selected location, and toggle the add customer modal
// *******************************************************************
import React, { useEffect, useReducer, createContext, ReactChild, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CompanyConfig, Location } from "models";
import { reducer } from "./reducer";
import { authStore } from "../auth-provider";
import { authenticatedFetch, getUserLocationsEndpoint, compareStringValues, getCompanyConfigEndpoint } from "shared";

export type AppState = {
    locationId: number | null;
    availableLocations: Array<Location>;
    error: Error | null;
    isLoadingApp: boolean;
    config: CompanyConfig | null;
};

export enum AppType {
    SelectLocation = "select location",
    ClearLocations = "clear locations",
    Error = "error",
    Success = "success",
    Load = "load",
}

interface LocationState {
    from: {
        pathname: string;
    };
}

type InitialStateType = {
    state: AppState;
    selectLocation: (locationId: number) => void;
    clearLocations: () => void;
};

const initialState: AppState = {
    locationId: null,
    availableLocations: [],
    isLoadingApp: false,
    error: null,
    config: null,
};

const appStore = createContext<InitialStateType>({
    state: initialState,
    selectLocation: function (locationId: number) {
        // implemented inside the provider
    },
    clearLocations: function () {
        // implemented inside the provider
    },
});

const { Provider } = appStore;

const AppProvider = ({ children }: { children: ReactChild }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const authContext = useContext(authStore);
    const routerLocation = useLocation<LocationState>();
    const routerHistory = useHistory();

    const selectLocation = (locationId: number) => {
        dispatch({ type: AppType.SelectLocation, locationId });
    };

    const clearLocations = () => {
        dispatch({ type: AppType.ClearLocations });
    };

    const routeToPage = (locations: Location[]) => {
        const { from } = routerLocation.state || { from: { pathname: "/" } };
        if (locations.length === 1) {
            // If there is only one location we route straight to the queue page
            const locationId = locations[0].id;
            selectLocation(locationId);
            routerHistory.replace(`/locations/${locationId}/queue`);
        } else {
            const locationIdFromRoute = from.pathname.replace(/\D/g, "");
            if (locationIdFromRoute) {
                selectLocation(parseInt(locationIdFromRoute, 10));
                routerHistory.replace(from.pathname);
            } else {
                routerHistory.replace("/locations");
            }
        }
    };

    const getLocations = async (userId: number, companyId: number): Promise<Location[]> => {
        try {
            const response = await authenticatedFetch(getUserLocationsEndpoint(companyId, userId));
            if (!response.ok) throw response;
            const userLocations = await response.json();
            const sortedLocations = userLocations.data?.sort(compareStringValues("displayName")) || [];
            return sortedLocations;
        } catch (error) {
            throw error;
        }
    };

    const getCompanyConfig = async (entityId: number) => {
        try {
            const response = await authenticatedFetch(getCompanyConfigEndpoint(entityId));
            if (!response.ok) throw response;
            const result = await response.json();
            return result.data;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        dispatch({ type: AppType.Load });
        const doFetch = async () => {
            if (authContext.state.isAuthenticated) {
                const user = authContext.state.user;
                try {
                    const availableLocations: Location[] = await getLocations(user.Id, user.ParentEntityId);
                    const config = await getCompanyConfig(user.ParentEntityId);
                    routeToPage(availableLocations);
                    dispatch({ type: AppType.Success, availableLocations, config });
                } catch (error) {
                    if (error instanceof TypeError) {
                        dispatch({
                            type: AppType.Error,
                            error,
                        });
                    } else {
                        dispatch({
                            type: AppType.Error,
                            error,
                        });
                        const { from } = routerLocation.state || { from: { pathname: "/" } };
                        routerHistory.replace(from.pathname);
                    }
                }
            } else {
                dispatch({ type: AppType.ClearLocations });
            }
        };
        doFetch();
        // If I add routeToPage() here it will create an infinite loop
        // eslint-disable-next-line
    }, [authContext.state.isAuthenticated]);

    return (
        <Provider
            value={{
                state,
                selectLocation,
                clearLocations,
            }}
        >
            {children}
        </Provider>
    );
};

export { appStore, AppProvider, Provider} ;
