import React, { useState } from "react";
import { Input } from "@iqmetrix/antd";
import { SelectLocationsModal } from "./SelectLocationsModal";
import { useFormatMessage } from "hooks";

export interface LocationInputProps {
    value?: string[];
    onChange?: (value: string[]) => void;
}

export const LocationInput: React.FC<LocationInputProps> = ({ value, onChange }) => {
    const inputPlaceholder = useFormatMessage("Request.report.card.input.placeholder");
    const locationsSelectedText = useFormatMessage("Create.report.modal.locations.selected", {
        numberOf: value?.length,
    });

    const [showModal, setShowModal] = useState(false);

    const onLocationsModalSubmit = (ids: string[]) => {
        setShowModal(false);
        onChange?.(ids);
    };

    const onLocationsModalCancel = () => {
        setShowModal(false);
    };

    return (
        <>
            <Input
                id="location-input-select"
                type="text"
                placeholder={inputPlaceholder}
                onClick={() => setShowModal(true)}
                value={value?.length ? locationsSelectedText : undefined}
            />
            <SelectLocationsModal
                visible={showModal}
                onCancel={onLocationsModalCancel}
                onSubmit={onLocationsModalSubmit}
                selectedLocationsIds={value || []}
            />
        </>
    );
};
