import React, { useContext } from "react";
import { Row, Col, Card, Skeleton, Empty, Typography, Layout, Space } from "@iqmetrix/antd";
import { appStore, authStore } from "providers";
import { verticalGutter, wholeGutter } from "shared";
import { CustomerHistory } from "models";
import { useFormatMessage, useResult } from "hooks";
import { LocationPane, AppMenu } from "components";
import { CustomerHistoryInfo } from "./components/customer-history-info";
import { useQueueHistory } from "./hooks/use-queue-history";

const { Paragraph } = Typography;

export const History: React.FC = () => {
    const { showResult, resultContent } = useResult();
    const {
        state: { locationId },
    } = useContext(appStore);
    const {
        state: { user },
    } = useContext(authStore);
    const historyState = useQueueHistory(user.ParentEntityId, locationId);

    const cardTitle: string = useFormatMessage("History.card.title");
    const cardSubTitle: string = useFormatMessage("History.card.subTitle");

    const isLoading = historyState.status === "empty";
    const customers = historyState.status === "ready" ? historyState.customers : [];
    if (showResult && resultContent) return resultContent;
    return (
        <Space direction="vertical" size="middle">
            <AppMenu></AppMenu>
            <LocationPane />
            <Layout className="content-container">
                <Card title={cardTitle} className="queue-card">
                    <Paragraph>{cardSubTitle}</Paragraph>
                    <Skeleton loading={isLoading} title={false} paragraph={{ rows: 3 }}>
                        {customers.length === 0 ? (
                            <Empty />
                        ) : (
                            customers.map((customer: CustomerHistory) => (
                                <Row gutter={[0, verticalGutter]} key={customer.name + customer.actionDateTime}>
                                    <Col span={wholeGutter}>
                                        <CustomerHistoryInfo
                                            phoneNumber={customer.phoneNumber}
                                            name={customer.name}
                                            queueState={customer.actionState}
                                            actionDate={customer.actionDateTime}
                                            reasonCode={customer.reasonCode}
                                        />
                                    </Col>
                                </Row>
                            ))
                        )}
                    </Skeleton>
                </Card>
            </Layout>
        </Space>
    );
};
