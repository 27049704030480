import React from "react";
import { Button } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { Customer } from "models";
import { useCustomerAction } from "../hooks/use-customer-action";

interface RequestFirstCustomerButtonProps {
    customer: Customer;
}

export const RequestFirstCustomerButton = ({ customer }: RequestFirstCustomerButtonProps) => {
    const { state: actionState, requestReturn } = useCustomerAction(customer);

    const requestReturnButtonText = useFormatMessage("Button.request.next.return");

    return (
        <Button type="default" block loading={actionState.status === "loading"} onClick={requestReturn}>
            {requestReturnButtonText}
        </Button>
    );
};
