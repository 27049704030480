// *******************************************************************
// Auth provider provides the application with every functionality
// related to the authentication; login and logout
// *******************************************************************
import React, { useEffect, useReducer, createContext, ReactChild, useContext } from "react";
import * as Sentry from "@sentry/react";
import { fetchAccessToken, authenticate, authorize, clearTokenFromStorage } from "shared/client";
import { AuthUser } from "models";
import { reducer } from "./reducer";
import { appStore } from "../app-provider";

export type AuthState = {
    isAuthenticated: boolean;
    hasPermission: boolean;
    user: AuthUser;
    error: Error | null;
    isAuthLoading: boolean;
};

export enum AuthType {
    Logout = "logout",
    Error = "error",
    Success = "success",
    Load = "load",
}

type InitialStateType = {
    state: AuthState;
    logout: () => void;
    login: () => void;
};

const initialState: AuthState = {
    isAuthLoading: false,
    hasPermission: false,
    isAuthenticated: false,
    user: {} as AuthUser,
    error: null,
};

const authStore = createContext<InitialStateType>({
    state: initialState,
    logout: function () {
        // implemented inside the provider
    },
    login: function () {
        // implemented inside the provider
    },
});
const { Provider } = authStore;

const AuthProvider = ({ children }: { children: ReactChild }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const appContext = useContext(appStore);

    const login = async () => {
        try {
            fetchAccessToken();
        } catch (error) {
            dispatch({ type: AuthType.Error, error });
        }
    };

    const logout = () => {
        dispatch({ type: AuthType.Load });
        try {
            clearTokenFromStorage();
            appContext.clearLocations();
            dispatch({ type: AuthType.Logout, user: {} as AuthUser });
        } catch (error) {
            dispatch({ type: AuthType.Error, error });
        }
    };

    useEffect(() => {
        dispatch({ type: AuthType.Load });
        const doFetch = async () => {
            try {
                const authUser: AuthUser = await authenticate();
                const queueManagementPermission = await authorize();
                const hasPermission = queueManagementPermission.Granted;
                const type = Object.keys(authUser).length > 0 ? AuthType.Success : AuthType.Logout;
                dispatch({ type, user: authUser, hasPermission });
                Sentry.setUser({
                    id: authUser?.Id.toString(),
                    email: authUser.Email,
                    username: authUser.UserName,
                });
                Sentry.setTag("parentEntityId", authUser.ParentEntityId.toString());
            } catch (error) {
                dispatch({ type: AuthType.Logout, user: {} as AuthUser });
            }
        };
        doFetch();
    }, []);

    return <Provider value={{ state, logout, login }}>{children}</Provider>;
};
export { authStore, AuthProvider };
