import React from "react";
import { Result } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";

export interface ResultMessageProps {
    error: Error;
}

export const ResultMessage = ({ error }: ResultMessageProps) => {
    const permissionTitle = useFormatMessage("Error.permission.header");
    const permissionSubTitle = useFormatMessage("Error.permission.subTitle");
    const pageNotWorkingTitle = useFormatMessage("Error.page.not.working.header");
    const pageNotWorkingSubTitle = useFormatMessage("Error.page.not.working.subTitle");
    const hasNoLocationsSubTitle = useFormatMessage("Error.no.locations.subTitle");
    const noNetworkTitle = useFormatMessage("Error.no.network.title");
    const noNetworkSubTitle = useFormatMessage("Error.no.network.subTitle");
    const timeOutSubTitle = useFormatMessage("Error.timeout.subTitle");

    const getTitle = () => {
        switch (error.message) {
            case "PermissionsDenied": {
                return permissionTitle;
            }
            case "NoLocations": {
                return permissionTitle;
            }
            case "NetworkLost":
            case "Timeout": {
                return noNetworkTitle;
            }
            default: {
                return pageNotWorkingTitle;
            }
        }
    };

    const getSubTitle = () => {
        switch (error.message) {
            case "PermissionsDenied": {
                return permissionSubTitle;
            }
            case "NoLocations": {
                return hasNoLocationsSubTitle;
            }
            case "Timeout": {
                return (
                    <p>
                        {timeOutSubTitle}
                        &nbsp;
                        <a href="mailto:support@iqmetrix.com">support@iqmetrix.com</a>
                    </p>
                );
            }
            case "NetworkLost": {
                return (
                    <p>
                        {noNetworkSubTitle}
                        &nbsp;
                        <a href="mailto:support@iqmetrix.com">support@iqmetrix.com</a>
                    </p>
                );
            }
            default: {
                return pageNotWorkingSubTitle;
            }
        }
    };

    return <Result title={getTitle()} icon={null} response={{ statusText: "error" }} subTitle={getSubTitle()}></Result>;
};
