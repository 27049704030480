import { Location } from "models";

const MINIMUM_SEARCH_LENGTH = 2;

export function filterLocations(filterValue: string, locations: Location[]) {
    if (filterValue.length > MINIMUM_SEARCH_LENGTH) {
        return locations.filter((location) => location.displayName.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
        return locations;
    }
}
