import React, { useState, useContext } from "react";
import ReactGA from "react-ga4";
import { saveAs } from "file-saver";
import { Card, Typography, Space, Button, DatePicker, Form, Layout, Spin, Alert, Row, Grid } from "@iqmetrix/antd";
import { AppMenu } from "components";
import { LocationInput } from "./components/LocationInput";
import { authStore, appStore } from "providers";
import moment from "moment";
import { useFormatMessage, useResult } from "hooks";
import { authenticatedFetch, dateFormat, getCustomerHistoryReport, getResponseMessage } from "shared";

const { Paragraph } = Typography;
const { useBreakpoint } = Grid;

export const Report: React.FC = () => {
    const { showResult, resultContent } = useResult();

    const [isRequestingReport, setIsRequestingReport] = useState(false);
    const [successfullDownload, setSuccessfulDownload] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {
        state: { user },
    } = useContext(authStore);
    const {
        state: { availableLocations },
    } = useContext(appStore);
    const [createReportForm] = Form.useForm();

    const requiredText = useFormatMessage("Add.customer.required.field");
    const exportText = useFormatMessage("Button.text.export");
    const cardTitle = useFormatMessage("Request.report.card.title");
    const cardSubtitle = useFormatMessage("Request.report.card.subtitle");
    const locationsLabel = useFormatMessage("Request.report.card.location.label");
    const alertTitle = useFormatMessage("Request.report.alert.title");
    const alertDescription = useFormatMessage("Request.report.alert.description");
    const startDate = useFormatMessage("Request.report.start.date");
    const endDate = useFormatMessage("Request.report.end.date");
    const defaultReportName = useFormatMessage("Request.report.default.name");

    const onFormSubmit = () => {
        createReportForm
            .validateFields()
            .then(async (values) => {
                setIsRequestingReport(true);
                try {
                    const startDate = moment(values.startDate).format(dateFormat);
                    const endDate = moment(values.endDate).format(dateFormat);
                    const locationIds = values.selectedLocationsIds.join(",");
                    const response = await authenticatedFetch(
                        getCustomerHistoryReport(user.ParentEntityId, startDate, endDate, locationIds)
                    );
                    if (!response.ok) throw response;
                    ReactGA.event({
                        category: `CompanyID ${user.ParentEntityId}`,
                        action: "Run Report",
                        label: `UserId ${user.Id}`,
                    });    
                    // get the content-disposition header to find the file name
                    const contentDispositionHeader = response.headers.get("content-disposition");
                    // assign the filename a default name incase we can not get the value from the header
                    const filename = contentDispositionHeader?.match(/(?:filename=)"(.*?)"/)?.[1] || defaultReportName;
                    // get the content of the file
                    const blob = await response.blob();
                    // download the file
                    saveAs(blob, filename);
                    setIsRequestingReport(false);
                    setSuccessfulDownload(true);
                    createReportForm.resetFields();
                } catch (error) {
                    setIsRequestingReport(false);
                    const errorMessage = await getResponseMessage(error);
                    setErrorMessage(errorMessage);
                    throw error;
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const screens = useBreakpoint();
    const showDesktopView = screens.lg;

    const startDateDisabled = (current: moment.Moment) => {
        // Can not select days before today, today or after end date
        const endDate = createReportForm.getFieldValue("endDate");
        return (current && current > moment().endOf("day")) || (endDate && current > endDate);
    };

    const endDateDisabled = (current: moment.Moment) => {
        // Can not select days before today, today or before start date
        const startDate = createReportForm.getFieldValue("startDate");
        return (current && current > moment().endOf("day")) || (startDate && current < startDate);
    };

    const onCloseAlert = () => {
        setSuccessfulDownload(false);
    };

    const onCloseErrorAlert = () => {
        setErrorMessage("");
    };

    const onFormValuesChange = () => {
        setSuccessfulDownload(false);
        setErrorMessage("");
    };

    if (showResult && resultContent) return resultContent;

    return (
        <Space direction="vertical" size="middle">
            <AppMenu></AppMenu>
            <Layout className="content-container">
                {successfullDownload && (
                    <Alert
                        type="success"
                        onClose={onCloseAlert}
                        description={alertDescription}
                        message={alertTitle}
                        closable
                    />
                )}
                {errorMessage?.length > 0 && (
                    <Alert type="error" onClose={onCloseErrorAlert} message={errorMessage} closable />
                )}
                <Card title={cardTitle}>
                    <Space direction="vertical" size="middle">
                        <Paragraph>{cardSubtitle}</Paragraph>
                        <Form
                            id="getReportForm"
                            form={createReportForm}
                            name="getReportForm"
                            layout="vertical"
                            onValuesChange={onFormValuesChange}
                        >
                            {isRequestingReport ? (
                                <Row justify="space-around" align="middle">
                                    <Spin size="large" />
                                </Row>
                            ) : (
                                <Space size="middle" direction={showDesktopView ? "horizontal" : "vertical"}>
                                    <Form.Item
                                        label={startDate}
                                        name="startDate"
                                        rules={[{ required: true, message: requiredText }]}
                                    >
                                        <DatePicker
                                            format={dateFormat}
                                            placeholder={dateFormat}
                                            disabledDate={startDateDisabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={endDate}
                                        name="endDate"
                                        rules={[{ required: true, message: requiredText }]}
                                    >
                                        <DatePicker
                                            format={dateFormat}
                                            disabledDate={endDateDisabled}
                                            placeholder={dateFormat}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={locationsLabel}
                                        name="selectedLocationsIds"
                                        rules={[{ required: true, message: requiredText }]}
                                        initialValue={
                                            availableLocations.length === 1
                                                ? availableLocations.map((x) => x.id.toString())
                                                : []
                                        }
                                    >
                                        <LocationInput />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            id="getReportForm_export"
                                            type="primary"
                                            onClick={onFormSubmit}
                                            className="export-button"
                                        >
                                            {exportText}
                                        </Button>
                                    </Form.Item>
                                </Space>
                            )}
                        </Form>
                    </Space>
                </Card>
            </Layout>
        </Space>
    );
};
