import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Form, List, Space } from "@iqmetrix/antd";
import { useFormatMessage, useResult } from "hooks";
import { LocationSearch } from "components";
import { appStore } from "providers";
import { filterLocations, responsiveColumnsVertical } from "shared";

const MINIMUM_LOCATIONS_TO_SHOW_SEARCH = 10;

export const Locations: React.FC = () => {
    const { showResult, resultContent } = useResult();
    const [search, setSearch] = useState("");
    const {
        selectLocation,
        state: { availableLocations },
    } = useContext(appStore);

    const locations = filterLocations(search, availableLocations);
    const showSearch = availableLocations.length >= MINIMUM_LOCATIONS_TO_SHOW_SEARCH;

    const history = useHistory();
    const locationsHeader = useFormatMessage("Locations.page");

    const onClickLocation = (locationId: number) => {
        selectLocation(locationId);
        history.push(`/locations/${locationId}/queue`);
    };

    if (showResult && resultContent) return resultContent;

    return (
        <Space className="content-space" direction="vertical" size="large">
            <Row justify="center">
                <Col {...responsiveColumnsVertical}>
                    <Card title={locationsHeader}>
                        {showSearch && (
                            <Form.Item>
                                <LocationSearch onChange={setSearch} />
                            </Form.Item>
                        )}
                        <List
                            dataSource={locations}
                            renderItem={(x) => (
                                <List.Item onClick={() => onClickLocation(x.id)}>{x.displayName}</List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
        </Space>
    );
};
