import React, { useContext } from "react";
import { useFormatMessage } from "hooks";
import { Button, Row, Col, Typography } from "@iqmetrix/antd";
import { authStore } from "providers";

const { Title } = Typography;

export const Landing: React.FC = () => {
    const { login } = useContext(authStore);

    return (
        <Row justify="center" align="middle" className="center-page-content">
            <Col>
                <Title level={3}>{useFormatMessage("Landing.page")}</Title>
                <Button type="primary" onClick={login} block>
                    {useFormatMessage("Landing.page.button")}
                </Button>
            </Col>
        </Row>
    );
};
