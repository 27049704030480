import { LocationState, LocationAction } from "./LocationProvider";
import { ReasonCode, LocationConfig } from "models";

type Action =
    | { type: "error"; error: Error }
    | { type: "loading" }
    | { type: "success"; config: LocationConfig; reasonCodes: Array<ReasonCode> };

export function reducer(state: LocationState, action: Action): LocationState {
    switch (action.type) {
        case LocationAction.Error:
            return {
                ...state,
                isLoading: false,
                error: action.error ? action.error : null,
            };
        case LocationAction.Load:
            return {
                ...state,
                isLoading: true,
            };
        case LocationAction.Success:
            return {
                ...state,
                isLoading: false,
                reasonCodes: action.reasonCodes,
                config: action.config,
            };
        default:
            throw new Error("Invalid action");
    }
}
