import React, { useContext } from "react";
import { Alert, Button, InfoCircleOutlined } from "@iqmetrix/antd";
import { updateStore } from "providers";
import "./update-alert.scss";

export const UpdateAlert: React.FC = () => {
    const {
        state: { isUpdateAvailable, isUpdateSuccessful },
        hideReleaseNotes,
    } = useContext(updateStore);

    const reloadApp = () => {
        window.location.reload();
    };

    interface UpdateAlertProps {
        IsUpdateAvailable: boolean;
        IsUpdateSuccessful: boolean;
    }

    const releaseNotesUrl = "https://support.iqmetrix.com/s/queuemanagement";

    const UpdateAlert: any = (props: UpdateAlertProps) => {
        const { IsUpdateAvailable, IsUpdateSuccessful } = props;

        if (IsUpdateAvailable) {
            return (
                <Alert
                    banner
                    icon={<InfoCircleOutlined />}
                    type="info"
                    message={
                        <>
                            A new version of this application is now available. Press refresh to update.
                            <Button type="text" onClick={reloadApp}>
                                Refresh
                            </Button>
                        </>
                    }
                />
            );
        } else if (IsUpdateSuccessful) {
            return (
                <Alert
                    banner
                    icon={<InfoCircleOutlined />}
                    type="success"
                    closable
                    message={
                        <>
                            The app has been updated successfully. Learn more about the new version
                            <Button type="text" href={releaseNotesUrl} target="_">
                                here
                            </Button>
                        </>
                    }
                    onClose={hideReleaseNotes}
                />
            );
        } else {
            return null;
        }
    };

    return <UpdateAlert IsUpdateAvailable={isUpdateAvailable} IsUpdateSuccessful={isUpdateSuccessful} />;
};
