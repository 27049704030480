import React from "react";
import { Form, Input } from "@iqmetrix/antd";
import parsePhoneNumber from "libphonenumber-js";
import { useFormatMessage } from "hooks";
import { phoneMaxNumber } from "shared";

interface AddPhoneNumber {
    isRequired: boolean;
}

export const AddPhoneNumber: React.FC<AddPhoneNumber> = ({ isRequired }: AddPhoneNumber) => {
    const phoneLabel: string = useFormatMessage("Add.customer.modal.phone");
    const requiredText: string = useFormatMessage("Add.customer.required.field");
    const phoneNumberMinLength: string = useFormatMessage("Add.customer.modal.phone.number.must.be.length");
    const phoneNumberNotValid: string = useFormatMessage("Add.customer.modal.phone.number.not.valid");

    const normalizePhoneNumber = (value: string) => {
        if (value) {
            const phoneNumber = parsePhoneNumber(value, "US");
            return phoneNumber?.formatNational();
        }
    };

    return (
        <Form.Item
            label={phoneLabel}
            name="phoneNumber"
            validateTrigger="onBlur"
            normalize={normalizePhoneNumber}
            rules={[
                {
                    required: isRequired,
                    message: requiredText,
                },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (value) {
                            const phoneNumber = parsePhoneNumber(value, "US");
                            if (phoneNumber?.isPossible()) {
                                if (phoneNumber?.isValid()) {
                                    return Promise.resolve();
                                }
                                // phone number is not a valid number like 0000000000
                                return Promise.reject(new Error(phoneNumberNotValid));
                            } else {
                                // phone number needs min length
                                return Promise.reject(new Error(phoneNumberMinLength));
                            }
                        } else {
                            // phone number is required
                            if (isRequired) {
                                return Promise.reject();
                            } else {
                                return Promise.resolve();
                            }
                        }
                    },
                }),
            ]}
        >
            <Input addonBefore="+1" name="phonenumber" type="tel" maxLength={phoneMaxNumber} />
        </Form.Item>
    );
};
