import "@iqmetrix/antd/dist/antd.css";

import "core-js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import { ErrorBoundary, Profiler, I18nProvider } from "./components";
import { AuthProvider, AppProvider } from "./providers";
import { HashRouter as Router } from "react-router-dom";

import { App } from "./App";
import { getEnvironment, getEnvironmentSuffix } from "shared";

import "index.scss";
import { TrackerId } from "shared/analytics";
import * as packageJson from "../package.json";

async function init() {
    const env = getEnvironment();
    const isOldUrl = window.location.href.includes("checkin");


    ReactGA.initialize(TrackerId);
    ReactGA.set({ env: env });

    if (process.env.NODE_ENV !== "development" && !isOldUrl) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: env,
            attachStacktrace: true,
            release: `${packageJson.name}@${packageJson.version}`,
            ignoreErrors: [
                // Ignore errors that are unactionable. These are due to the user's network.
                "TypeError: Failed to fetch",
                "TypeError: NetworkError when attempting to fetch resource.",
                "TypeError: Cancelled",
            ],
        });
    }

    const Application = () => (
        <Profiler>
            <ErrorBoundary>
                <I18nProvider>
                    <Router>
                        <AuthProvider>
                            <AppProvider>
                                <App />
                            </AppProvider>
                        </AuthProvider>
                    </Router>
                </I18nProvider>
            </ErrorBoundary>
        </Profiler>
    );

    if (isOldUrl) {
        // redirect from old url
        window.location.replace(`https://queue${getEnvironmentSuffix()}.iqmetrix.net`);
    } else {
        ReactDOM.render(<Application />, document.getElementById("root"));
    }
}

init();
