import * as React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import { RouteComponentProps, WithGARouter } from "./interfaces";

import { getEnvironment } from "../get-environment";

const env = getEnvironment();

export const TrackerId =  env === "prod" ?  "G-P1L127CHCP":  "G-NTSXWV4HBQ";

export enum EventActions {
    AppAccessed = "App Accessed",
}


export const withGARouter: WithGARouter = (WrappedComponent, options = {}) => {
    const trackPage = (page: string) => {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.send({ hitType: "pageview", page });
    };
  
    const HOC: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
      const { location } = props;
      const page = location.pathname || "";
  
      useEffect(() => {
        if (page) trackPage(page);
      }, [page]);
  
      return <WrappedComponent {...props} />;
    };
  
    return HOC;
  };
  
  export * from "./interfaces";