import { useEffect, useReducer } from "react";
import ReactGA from "react-ga4";
import moment from "moment";
import { CustomerHistory } from "models";
import { ResponseError, authenticatedFetch, dateFormat, getHistoryEndpoint } from "shared";

const POLLING_RATE = 10000; // ms

type Action = { type: "success"; customers: Array<CustomerHistory> } | { type: "error"; error: Error };

type State =
    | { status: "empty" }
    | { status: "error"; error: Error }
    | { status: "ready"; customers: Array<CustomerHistory> };

const initialState: State = { status: "empty" };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "success":
            return {
                status: "ready",
                customers: action.customers,
            };
        case "error":
            return {
                status: "error",
                error: action.error,
            };
        default:
            throw new Error("Invalid action");
    }
}

export function useQueueHistory(companyId: number, locationId: number | null) {
    const [state, dispatch] = useReducer(reducer, initialState);

    async function fetchQueueHistory(companyId: number, locationId: number) {
        try {
            const date = moment().format(dateFormat);
            const url = getHistoryEndpoint(companyId, locationId, date);
            const response = await authenticatedFetch(url);
            if (!response.ok) {
                throw ResponseError.create(response);
            }
            const json = await response.json();
            const customers = json.data as Array<CustomerHistory>;
            ReactGA.event({
                category: `CompanyID ${companyId}`,
                action: "View History",
                label: `LocationId ${locationId}`,
            });
            dispatch({ type: "success", customers });
        } catch (error) {
            dispatch({ type: "error", error });
        }
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (companyId && locationId) {
            fetchQueueHistory(companyId, locationId);
            const doFetch = () => fetchQueueHistory(companyId, locationId);
            intervalId = setInterval(doFetch, POLLING_RATE);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [companyId, locationId]);

    return state;
}
