import { useContext } from "react";
import { useReducer } from "react";
import { Customer, CustomerAction } from "models";
import { doCustomerAction } from "shared/client/customer";
import { authStore } from "providers";

type Action =
    | { type: "reset" }
    | { type: "action"; action: CustomerAction }
    | { type: "success" }
    | { type: "error"; error: Error };

type State =
    | { status: "empty" }
    | { status: "ready" }
    | { status: "loading"; action: CustomerAction }
    | { status: "error"; error: Error };

const initialState: State = { status: "empty" };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "reset":
            return {
                status: "empty",
            };
        case "action":
            return {
                status: "loading",
                action: action.action,
            };
        case "success":
            return {
                status: "ready",
            };
        case "error":
            return {
                status: "error",
                error: action.error,
            };
        default:
            throw new Error("Invalid action");
    }
}

export function useCustomerAction(customer: Customer) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const authContext = useContext(authStore);
    const { user } = authContext.state;

    async function doAction(action: CustomerAction) {
        dispatch({ type: "action", action });
        try {
            await doCustomerAction(action, customer, user.Id);
            dispatch({ type: "success" });
        } catch (error) {
            dispatch({ type: "error", error });
        }
    }

    const remove = () => doAction("Remove");
    const requestReturn = () => doAction("RequestReturn");
    const requestApproach = () => doAction("RequestApproach");
    const markDone = () => doAction("MarkDone");

    return {
        state,
        remove,
        requestReturn,
        requestApproach,
        markDone,
    };
}
