import React, { useReducer, createContext, ReactChild, useContext, useEffect } from "react";
import { LocationConfig, ReasonCode } from "models";
import { reducer } from "./reducer";
import { authStore } from "../auth-provider";
import { appStore } from "../app-provider";
import { ResponseError, authenticatedFetch, getLocationConfigEndpoint, getReasonCodesEndpoint } from "shared";

export type LocationState = {
    error: Error | null;
    isLoading: boolean;
    config: LocationConfig | null;
    reasonCodes: Array<ReasonCode>;
};

export enum LocationAction {
    Error = "error",
    Load = "loading",
    Success = "success",
}

export type InitialStateType = {
    state: LocationState;
};

const initialState: LocationState = {
    error: null,
    isLoading: false,
    config: null,
    reasonCodes: [],
};

const locationStore = createContext<InitialStateType>({
    state: initialState,
});

const { Provider } = locationStore;

const LocationProvider = ({ children }: { children: ReactChild }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const authContext = useContext(authStore);
    const appContext = useContext(appStore);

    const getLocationConfig = async (entityId: number, locationId: number) => {
        try {
            const response = await authenticatedFetch(getLocationConfigEndpoint(entityId, locationId));
            if (!response.ok) throw response;
            const result = await response.json();
            const config = result.data;
            return config;
        } catch (error) {
            throw error;
        }
    };

    const getReasonCodes = async (entityId: number, locationId: number) => {
        try {
            const url = getReasonCodesEndpoint(entityId, locationId);
            const response = await authenticatedFetch(url);
            if (!response.ok) {
                throw await ResponseError.create(response);
            }
            const json = await response.json();
            const reasonCodes = json.data as Array<ReasonCode>;
            return reasonCodes;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        dispatch({ type: LocationAction.Load });
        const entityId = authContext.state.user.ParentEntityId;
        const locationId = appContext.state.locationId;
        const getLocationDetails = async () => {
            if (locationId) {
                try {
                    const config = await getLocationConfig(entityId, locationId);
                    const reasonCodes = await getReasonCodes(entityId, locationId);
                    dispatch({ type: "success", reasonCodes, config });
                } catch (error) {
                    dispatch({ type: "error", error });
                }
            }
        };

        getLocationDetails();
    }, [authContext.state.user.ParentEntityId, appContext.state.locationId]);

    return (
        <Provider
            value={{
                state,
            }}
        >
            {children}
        </Provider>
    );
};

export { locationStore, LocationProvider };
