import { AppState, AppType } from "./AppProvider";
import { CompanyConfig, Location } from "models";

type Action =
    | { type: "load" }
    | { type: "success"; availableLocations: Array<Location>; config: CompanyConfig }
    | { type: "clear locations" }
    | { type: "select location"; locationId: number | null }
    | { type: "error"; error: Error | null };

export function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case AppType.Error:
            return {
                ...state,
                isLoadingApp: false,
                error: action.error ? action.error : null,
            };
        case AppType.Success:
            return {
                ...state,
                isLoadingApp: false,
                error: null,
                availableLocations: action.availableLocations,
                config: action.config,
            };
        case AppType.Load:
            return { ...state, isLoadingApp: true };
        case AppType.ClearLocations:
            return {
                ...state,
                isLoadingApp: false,
                error: null,
                locationId: null,
                availableLocations: [],
            };
        case AppType.SelectLocation:
            return {
                ...state,
                locationId: action.locationId,
            };
        default:
            throw new Error("Invalid action");
    }
}
