import React, { useState, useContext, useEffect } from "react";
import { Input, Radio, Typography, Modal, Form, Alert, Skeleton } from "@iqmetrix/antd";
import { ScheduleReturnTime, AddPhoneNumber, ReasonForVisit } from "components";
import { useFormatMessage } from "hooks";
import { appStore } from "providers";
import moment from "moment";
import { useManuallyAddCustomer } from "../hooks/use-manually-add-customer";

const { Paragraph } = Typography;

interface Props {
    visible: boolean;
    toggleModal: (showModal: boolean) => void;
    reloadQueue: () => void;
}

export const AddCustomerModal: React.FC<Props> = ({ visible, toggleModal, reloadQueue }) => {
    const [userOptedIntoText, setUserOptedIntoText] = useState(true);
    const { manuallyAddState, manuallyAddCustomer, reset } = useManuallyAddCustomer();
    const [addCustomerForm] = Form.useForm();
    const {
        state: { config },
    } = useContext(appStore);

    const closeModal = () => {
        setUserOptedIntoText(true);
        toggleModal(false);
        addCustomerForm.resetFields();
        reset();
    };

    useEffect(() => {
        if (manuallyAddState.status === "ready") {
            reloadQueue();
            closeModal();
        }
        // eslint-disable-next-line
    }, [manuallyAddState]);

    const onFormSubmit = () => {
        addCustomerForm
            .validateFields()
            .then(async (values) => {
                const scheduledDateTime = values.scheduledDateTime
                    ? moment.utc(values.scheduledDateTime).format("YYYY-MM-DDTHH:mm")
                    : null;
                // strip non-digit characters
                const phoneNumber = values?.phoneNumber?.replace(/[^0-9]/g, "");
                manuallyAddCustomer({
                    name: values.name,
                    textCommunicationEnabled: values.textCommunicationEnabled,
                    phoneNumber,
                    scheduledDateTime,
                    reasonCode: values.reasonCode,
                });
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const nameLabel: string = useFormatMessage("Add.customer.modal.name");
    const textCommunicationLabel: string = useFormatMessage("Add.customer.modal.how.to.add.customer");
    const requiredText: string = useFormatMessage("Add.customer.required.field");
    const textMessageText: string = useFormatMessage("Add.customer.modal.how.to.add.customer.text");
    const textMessageSmallText: string = useFormatMessage("Add.customer.modal.how.to.add.customer.text.small.text");
    const scheduleText: string = useFormatMessage("Add.customer.modal.how.to.add.customer.schedule");
    const scheduleSmallText: string = useFormatMessage("Add.customer.modal.how.to.add.customer.schedule.small.text");
    const addCustomerText: string = useFormatMessage("Add.customer.modal.add.customer");

    return (
        <Modal
            title={addCustomerText}
            visible={visible}
            okText={addCustomerText}
            onCancel={closeModal}
            onOk={onFormSubmit}
            okButtonProps={{ disabled: manuallyAddState.status === "load" }}
            cancelButtonProps={{ disabled: manuallyAddState.status === "load" }}
            maskClosable={false}
            destroyOnClose
            centered
        >
            {manuallyAddState.status === "error" && <Alert type="error" message={manuallyAddState.error} />}
            <Skeleton loading={manuallyAddState.status === "load"} title={false} paragraph={{ rows: 3 }}>
                <Form
                    form={addCustomerForm}
                    name="addCustomer"
                    layout="vertical"
                    initialValues={{ textCommunicationEnabled: true }}
                >
                    <Form.Item label={textCommunicationLabel} name="textCommunicationEnabled">
                        <Radio.Group className="ghost-radio-buttons">
                            <Radio.Button value={true} onChange={() => setUserOptedIntoText(true)}>
                                {textMessageText}
                            </Radio.Button>
                            <Radio.Button value={false} onChange={() => setUserOptedIntoText(false)}>
                                {scheduleText}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Paragraph> {userOptedIntoText ? textMessageSmallText : scheduleSmallText} </Paragraph>
                    <Form.Item label={nameLabel} name="name" rules={[{ required: true, message: requiredText }]}>
                        <Input type="text" />
                    </Form.Item>
                    <AddPhoneNumber isRequired={userOptedIntoText} />
                    {!userOptedIntoText && <ScheduleReturnTime />}
                    {config?.features.supportsReasonCodes && <ReasonForVisit />}
                </Form>
            </Skeleton>
        </Modal>
    );
};
