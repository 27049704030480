import React, { useContext } from "react";
import { useNetworkDetection } from "./use-network-detection";
import { ResultMessage } from "../components";
import { appStore, authStore } from "../providers";

export const useResult = (): { showResult: boolean; resultContent: JSX.Element } => {
    const isOnline = useNetworkDetection();
    const {
        state: { hasPermission },
    } = useContext(authStore);
    const {
        state: { availableLocations },
    } = useContext(appStore);

    let resultContent: JSX.Element = <ResultMessage error={new Error("PermissionsDenied")} />;
    let showResult = !isOnline || !hasPermission || availableLocations.length === 0;

    if (!isOnline) {
        resultContent = <ResultMessage error={new Error("NetworkLost")} />;
    } else if (!hasPermission) {
        resultContent = <ResultMessage error={new Error("PermissionsDenied")} />;
    } else if (availableLocations.length === 0) {
        resultContent = <ResultMessage error={new Error("NoLocations")} />;
    }

    return { showResult, resultContent };
};
