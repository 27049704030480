export const getEnvironmentSuffix = (): string => {
    const host = window.location.host.toLowerCase();
    const parts = /(?:checkin|queue)(.*?)\./.exec(host);
    const environmentSuffix = parts && parts[1];
    switch (environmentSuffix) {
        case "int":
        case "rc":
        case "":
            return environmentSuffix;
        default:
            return "int";
    }
};
