import React, { useContext } from "react";
import moment, { CalendarSpec } from "moment";
import { Row, Col, Tag } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { horizontalGutter, verticalGutter, halfGutter, wholeGutter, timeFormat } from "shared";
import { appStore } from "providers";
import { QueueState, ReasonCode } from "models";
import { CustomerInfoModal } from "components";

interface CustomerInfo {
    name: string;
    phoneNumber: string;
    date: string;
    queueState: QueueState;
    reasonCode?: ReasonCode;
    arrivedDate?: string;
    preferredLanguage?: string;
}

export const CustomerInfo: React.FC<CustomerInfo> = (props: CustomerInfo) => {
    const { name, date, phoneNumber, queueState, reasonCode, arrivedDate, preferredLanguage } = props;

    const localizedDatetimeFormat = useFormatMessage("DatetimeFormat");
    const localizedCalendarSpec: CalendarSpec = {
        lastWeek: localizedDatetimeFormat,
        nextWeek: localizedDatetimeFormat,
        sameElse: localizedDatetimeFormat,
    };

    const parsedDate: string = moment(date).calendar(null, localizedCalendarSpec);
    const parsedArriveDate: string = moment(arrivedDate).format(timeFormat);
    const returnRequestedString = useFormatMessage("Requested", { queuedDate: parsedDate });
    const scheduledForString = useFormatMessage("Scheduled.for", { queuedDate: parsedDate });
    const arrivedString = useFormatMessage("Arrived", { arrivedDate: parsedArriveDate });
    const joinedString = useFormatMessage("Joined", { dateString: parsedDate });
    const returnedString = useFormatMessage("Returned", { dateString: parsedDate });
    const timestampAtString = useFormatMessage("Timestamp.at", { queuedDate: moment(date).format(timeFormat) });

    const {
        state: { config },
    } = useContext(appStore);

    const verticalGutterValue = verticalGutter;
    const colSpan = halfGutter;

    const formatTimeString = (queueState: QueueState) => {
        switch (queueState) {
            case "ReturnRequested":
                return returnRequestedString;
            case "InLine":
                return joinedString;
            case "Scheduled":
                return scheduledForString;
            case "Waiting":
                return returnedString;
            default:
                return timestampAtString;
        }
    };

    const hasReasonCode = config?.features.supportsReasonCodes && reasonCode;
    const hasPreferredLanguage = config?.features.smsLanguageOptionsEnabled && preferredLanguage;

    return (
        <>
            <Row gutter={[horizontalGutter, verticalGutterValue]} align="middle">
                <Col span={colSpan}>
                    <CustomerInfoModal phoneNumber={phoneNumber} name={name} queueState={queueState} />
                </Col>
                <Col span={colSpan} className="text-right">
                    {formatTimeString(queueState)}
                </Col>
            </Row>
            {arrivedDate && (
                <Row gutter={[horizontalGutter, verticalGutterValue]} align="middle">
                    <Col className="text-right" span={wholeGutter}>
                        {arrivedString}
                    </Col>
                </Row>
            )}
            
            {(hasReasonCode || hasPreferredLanguage) && (
                <Row gutter={[horizontalGutter, verticalGutter]} align="middle" justify="start">
                    <Col className="text-right" span={wholeGutter}>                        
                        {hasReasonCode && (
                        <Tag> {reasonCode?.displayName} </Tag>
                        )}
                        <span>  </span>
                        {hasPreferredLanguage && (
                        <Tag> {preferredLanguage} </Tag>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
};
