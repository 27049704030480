import { useReducer, useContext } from "react";
import ReactGA from "react-ga4";
import { getCustomerEndpoint, authenticatedFetch, getResponseMessage } from "shared";
import { authStore, appStore } from "providers";

type Action = { type: "reset" } | { type: "success" } | { type: "loading" } | { type: "error"; error: string };

interface State {
    status: "empty" | "error" | "ready" | "load";
    error: string | null;
}

const initialState: State = { status: "empty", error: null };

interface ManuallyAddCustomerValues {
    name: string;
    textCommunicationEnabled: boolean;
    phoneNumber: string;
    scheduledDateTime: string | null;
    reasonCode: { id: string };
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "reset":
            return {
                ...state,
                error: null,
                status: "empty",
            };
        case "success":
            return {
                ...state,
                error: null,
                status: "ready",
            };
        case "loading":
            return {
                ...state,
                status: "load",
            };
        case "error":
            return {
                ...state,
                status: "error",
                error: action.error,
            };
        default:
            throw new Error("Invalid action");
    }
}

export function useManuallyAddCustomer() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const authContext = useContext(authStore);
    const appContext = useContext(appStore);
    const { user } = authContext.state;
    const { locationId } = appContext.state;

    const reset = () => {
        dispatch({ type: "reset" });
    };

    const manuallyAddCustomer = async (values: ManuallyAddCustomerValues) => {
        dispatch({ type: "loading" });
        if (locationId) {
            const url = getCustomerEndpoint(user.ParentEntityId, locationId, user.Id);
            try {
                const res = await authenticatedFetch(url, {
                    method: "POST",
                    body: JSON.stringify({
                        CustomerName: values.name,
                        SMSNotificationsEnabled: values.textCommunicationEnabled,
                        ...(values.reasonCode && {
                            ReasonCode: {
                                id: values.reasonCode,
                            },
                        }),
                        ...(values.phoneNumber && {
                            PhoneNumber: values.phoneNumber,
                        }),
                        ...(values.scheduledDateTime && {
                            ScheduledDateTime: values.scheduledDateTime,
                        }),
                    }),
                });
                if (!res.ok && res.status >= 300) throw res;
                const action = values.scheduledDateTime !== null ? "Schedule" : "Text";
                ReactGA.event({
                    category: `CompanyID ${user.ParentEntityId}`,
                    action: `Add customer - ${action}`,
                    label: `CustomerID ${user.Id}`,
                });
                dispatch({ type: "success" });
            } catch (error) {
                const errorMessage = await getResponseMessage(error);
                dispatch({ type: "error", error: errorMessage });
                throw error;
            }
        }
    };

    return { manuallyAddState: state, manuallyAddCustomer, reset };
}
