import { getEnvironment } from "./get-environment";
import { getEnvironmentSuffix } from "./get-environment-suffix";

const env = getEnvironment();
const suffix = getEnvironmentSuffix();

const QueueManagementAPIRoot = `https://checkin${env}.azure.${
    env === "int" ? "development" : "production"
}.k8s.iqmetrix.net/v1`;
const IQAccountsAPIRoot = `https://accounts${suffix}.iqmetrix.net`;

export const getActionEndpoint = (companyId: number, locationId: number, customerId: string, userId: number) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/locations/${locationId}/customers/${customerId}?userId=${userId}`;
};

export const getCustomerEndpoint = (companyId: number, locationId: number, userId: number, date?: string) => {
    const search = new URLSearchParams({ userId: userId.toString() });
    if (date) {
        search.set("date", date);
    }
    const url = new URL(`${QueueManagementAPIRoot}/companies/${companyId}/locations/${locationId}/customers`);
    url.search = search.toString();
    return url.toString();
};

export const getCustomerHistoryReport = (
    companyId: number,
    startDate: string,
    endDate: string,
    locationIds: string
) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/customers/history/report?startDate=${startDate}&endDate=${endDate}&locationIds=${locationIds}`;
};

export const getCompanyConfigEndpoint = (companyId: number) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/configuration`;
};

export const getLocationConfigEndpoint = (companyId: number, locationId: number) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/locations/${locationId}/configuration`;
};

export const getReasonCodesEndpoint = (companyId: number, locationId: number) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/locations/${locationId}/reason-codes`;
};

export const getHistoryEndpoint = (companyId: number, locationId: number, date: string) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/locations/${locationId}/customers/history?date=${date}`;
};

export const getUserLocationsEndpoint = (companyId: number, userId: number) => {
    return `${QueueManagementAPIRoot}/companies/${companyId}/users/${userId}/assigned-locations`;
};

export const getPermissions = (code: string) => {
    return `${IQAccountsAPIRoot}/v1/permissions?code=${code}`;
};

export const getLogoutEndpoint = () => {
    return `${IQAccountsAPIRoot}/logout`;
};

export const getLoginEndpoint = () => {
    return `${IQAccountsAPIRoot}/v1/oauth2/auth`;
};

export const getMeEndpoint = () => {
    return `${IQAccountsAPIRoot}/v1/me`;
};

export const getAppVersionEndpoint = () => {
    if (process.env.NODE_ENV !== "development") {
        return "/ping";
    }
    return `/version.json`;
};
