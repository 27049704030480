export class ResponseError extends Error {
    private _response: Response;

    public get response(): Response {
        return this._response;
    }

    private constructor(message: string, res: Response) {
        super(message);
        this.name = "ResponseError";
        this._response = res;
    }

    /**
     * Factory method to create a new ResponseError
     */
    public static async create(res: Response) {
        let message: string;
        try {
            const json = await res.json();
            message = json.errors.map((e: { message: string }) => e.message).join("\n");
        } catch (e) {
            // do nothing we're already failing!
            message = "Unknown Error";
        }
        return new ResponseError(message, res);
    }
}
