import React, { useContext } from "react";
import moment from "moment";
import { Row, Col, Card, Typography } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { appStore } from "providers";
import { ReasonCode, QueueState } from "models";
import { CustomerInfoModal } from "components";
import { timeFormat } from "shared";

const { Text } = Typography;

interface CustomerInfo {
    name: string;
    phoneNumber: string;
    queueState: QueueState;
    actionDate: string;
    reasonCode: ReasonCode;
}

export const CustomerHistoryInfo: React.FC<CustomerInfo> = (props: CustomerInfo) => {
    const { name, phoneNumber, queueState, actionDate, reasonCode } = props;
    const {
        state: { config },
    } = useContext(appStore);

    const timestampAtString = useFormatMessage("Timestamp.at", { queuedDate: moment(actionDate).format(timeFormat) });

    const formatQueueStateToTitleCase = (queueState: QueueState) => {
        const [firstQueueState, ...otherQueueState] = queueState.split(/(?=[A-Z])/);
        return [firstQueueState, ...otherQueueState.map((x) => x.toLowerCase())].join(" ");
    };

    return (
        <Card>
            <Row align="middle">
                <Col span={10}>
                    <CustomerInfoModal phoneNumber={phoneNumber} name={name} queueState={queueState} />
                </Col>
                {config?.features.supportsReasonCodes && reasonCode && (
                    <Col span={8}>
                        <Text> {reasonCode.displayName} </Text>
                    </Col>
                )}
                <Col span={6} className="text-right">
                    <Text>
                        {formatQueueStateToTitleCase(queueState)} {timestampAtString}
                    </Text>
                </Col>
            </Row>
        </Card>
    );
};
