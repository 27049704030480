import React, { useContext, useEffect, useState } from "react";
import { Form, Typography, Checkbox, Divider, List, Button, Modal } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { LocationSearch } from "components";
import { appStore } from "providers";
import { filterLocations } from "shared";

const { Paragraph } = Typography;

interface CreateReportForm {
    visible: boolean;
    onCancel: () => void;
    onSubmit: (ids: string[]) => void;
    selectedLocationsIds: string[];
}

export const SelectLocationsModal: React.FC<CreateReportForm> = ({
    visible,
    onCancel,
    onSubmit,
    selectedLocationsIds,
}: CreateReportForm) => {
    const {
        state: { availableLocations },
    } = useContext(appStore);

    const [selectedIds, setSelectedIds] = useState(selectedLocationsIds);
    const [search, setSearch] = useState("");

    const filteredLocations = filterLocations(search, availableLocations);
    const entireCompanySelected = selectedIds.length === availableLocations.length;

    const resetSelectedIds = () => {
        setSelectedIds(selectedLocationsIds);
    };

    const onLocationFormSubmit = () => {
        onSubmit(selectedIds);
    };

    const checkEntireCompany = (isChecked: boolean) => {
        const newValue = isChecked ? availableLocations.map((item) => item.id.toString()) : [];
        setSelectedIds(newValue);
    };

    const toggleCheckbox = (id: string, isChecked: boolean) => {
        if (isChecked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    useEffect(resetSelectedIds, [selectedLocationsIds, visible]);

    const modalHeader = useFormatMessage("Create.report.modal.select.location");
    const byLocationText = useFormatMessage("Create.report.modal.by.location");
    const entireCompanyText = useFormatMessage("Create.report.modal.entire.company");
    const selectedLocationText = useFormatMessage("Create.report.modal.locations.selected", {
        numberOf: selectedIds.length,
    });
    const cancelText = useFormatMessage("Customer.cancel");
    const selectText = useFormatMessage("Button.select");

    return (
        <Modal
            title={modalHeader}
            visible={visible}
            onCancel={onCancel}
            maskClosable={false}
            destroyOnClose
            centered
            footer={[
                <Paragraph key="locationNumber" className="location-number-text" disabled={selectedIds.length === 0}>
                    {selectedLocationText}
                </Paragraph>,
                <Button key="close" onClick={onCancel}>
                    {cancelText}
                </Button>,
                <Button key="submit" type="primary" onClick={onLocationFormSubmit}>
                    {selectText}
                </Button>,
            ]}
        >
            <Form name="selectLocation" layout="vertical">
                <Form.Item name="entireCompany">
                    <Checkbox
                        id="select-entire-company"
                        checked={entireCompanySelected}
                        onChange={(event) => checkEntireCompany(event.target.checked)}
                    >
                        {entireCompanyText}
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Paragraph> {byLocationText} </Paragraph>
                <Divider />
                {availableLocations.length > 1 && (
                    <Form.Item name="location">
                        <LocationSearch onChange={setSearch} />
                    </Form.Item>
                )}
                <Form.Item name="selectedLocations">
                    <List
                        className="fixed-height"
                        dataSource={filteredLocations}
                        itemLayout="vertical"
                        renderItem={(x) => (
                            <List.Item key={x.id}>
                                <Checkbox
                                    checked={selectedIds.includes(x.id.toString())}
                                    onChange={(e) => toggleCheckbox(x.id.toString(), e.target.checked)}
                                >
                                    {x.displayName}
                                </Checkbox>
                            </List.Item>
                        )}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
