import { getAccessToken, clearTokenFromStorage } from "./client";
import * as Sentry from "@sentry/minimal";

export type AuthenticatedFetch = (url: RequestInfo, requestInit?: RequestInit) => Promise<Response>;

export const authenticatedFetch: AuthenticatedFetch = async (url, requestInit?) => {
    try {
        let authenticationToken;
        let options = requestInit || {};
        let headers = (options && options.headers) || {};
        try {
            authenticationToken = getAccessToken();
        } catch (error) {
            window.location.href = "/";
        }

        headers = { ...headers, "Content-Type": "application/json", Authorization: `Bearer ${authenticationToken}` };
        options = { ...options, headers };

        const response = await fetch(url, options);
        if (response && response.status === 401) {
            clearTokenFromStorage();
        }

        return response;
    } catch (error) {
        /* istanbul ignore next */
        Sentry.captureException(error);
        throw error;
    }
};
