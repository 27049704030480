import { message } from "@iqmetrix/antd";
import ReactGA from "react-ga4";
import { authenticatedFetch, getActionEndpoint, getResponseMessage, ResponseError } from "shared";
import { Customer, CustomerAction } from "models";

export async function doCustomerAction(action: CustomerAction, customer: Customer, userId: number) {
    const url = getActionEndpoint(customer.companyId, customer.locationId, customer.id, userId);
    try {
        const res = await authenticatedFetch(url, { method: "POST", body: JSON.stringify({ Action: action }) });
        if (!res.ok) {
            throw ResponseError.create(res);
        }
        const resMessage = await getResponseMessage(res);
        message.success(resMessage);
        ReactGA.event({
            category: `CompanyID ${customer.companyId} `,
            action: action,
            label: `CustomerID ${customer.id}`,
        });
    } catch (error) {
        const errorRes = await error;
        message.error(errorRes.message);
        throw error;
    }
}
