import React, { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Menu } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";
import { appStore } from "providers";

export const AppMenu: React.FC = () => {
    const {
        state: { locationId },
    } = useContext(appStore);
    const location = useLocation();
    let history = useHistory();
    const pathName = location.pathname;
    const activeQueueTitle = useFormatMessage("App.menu.active.queue.title");
    const historyTitle = useFormatMessage("App.menu.history.title");
    const reportTitle = useFormatMessage("App.menu.report.title");

    const changeMenu = (event: any) => {
        history.push(`/locations/${locationId}/${event.key}`);
    };

    let currentMenuItem = "queue";

    if (pathName.includes("history")) {
        currentMenuItem = "queue/history";
    } else if (pathName.includes("report")) {
        currentMenuItem = "queue/report";
    }

    return (
        <Menu onClick={changeMenu} mode="horizontal" selectedKeys={[currentMenuItem]}>
            <Menu.Item key="queue" id="queue-menu-item">
                {activeQueueTitle}
            </Menu.Item>
            <Menu.Item key="queue/history" id="history-menu-item">
                {historyTitle}
            </Menu.Item>
            <Menu.Item key="queue/report" id="report-menu-item">
                {reportTitle}
            </Menu.Item>
        </Menu>
    );
};
