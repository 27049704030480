import React from "react";

const onRenderCallback: React.ProfilerOnRenderCallback = (id, phase, actualDuration) =>
    console.info(`Phase: ${phase}, Duration: ${actualDuration.toFixed(2)}ms`);

export const Profiler: React.FC = ({ children }) =>
    process.env.NODE_ENV === "development" ? (
        <React.Profiler id="application" onRender={onRenderCallback}>
            {children}
        </React.Profiler>
    ) : (
        <>{children}</>
    );
