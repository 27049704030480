import React from "react";
import { Input, SearchOutlined } from "@iqmetrix/antd";
import { useFormatMessage } from "hooks";

interface LocationSearch {
    onChange: (search: string) => void;
}

export const LocationSearch: React.FC<LocationSearch> = ({ onChange}) => {
    const inputPlaceholder = useFormatMessage("Input.search");

    return (
        <Input
            placeholder={inputPlaceholder}
            prefix={<SearchOutlined />}
            onChange={(event) => onChange(event.target.value)}
        />
    );
};
