export async function getResponseMessage(res: Response): Promise<string> {
    try {
        const resJson = await res.json();
        let message = "";
        if (resJson.data) {
            message = resJson.data.message;
        } else if (resJson.errors) {
            message = resJson.errors[0].message || resJson.errors[0][0].message;
        }
        return message;
    } catch (error) {
        return "Unexpected error. Please try again.";
    }
}
